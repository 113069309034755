import React from 'react'

interface props{
    err : string[]
}

const ErrorDisplay:React.FC<props> = ({err}) => {
  return (
    <>
    {err && err.length > 0 && <div className='flex flex-col'>
        { err.map((item, i) => 
            <span className='text-red-500' key={i}>* {item}</span>
        )}
    </div>}
    </>
  
  )
}

export default ErrorDisplay
