import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface StateType {
  hotelId : number | null
  bookingDate: string
}
 
const initialState:StateType = {
  hotelId: null,
  bookingDate: ""
};

export const OrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setBookingData: (state, action: PayloadAction<StateType>) => {
      state.hotelId = action.payload.hotelId;
      state.bookingDate = action.payload.bookingDate
    },

  },
});

export const {
    setBookingData,
} = OrderSlice.actions;


export default OrderSlice.reducer;
