import axios from "src/utils/axios";
import SuccessMessage from "./successMessage";
import { getToken } from "./localStorageHelper";
export interface fetcherProps {
    url: string
    method: "GET" | "POST" | "DELETE" | "UPDATE"
    data?: object
    headers?: object
    alert?:boolean
}

async function httpFunction({url, method, data, headers, alert=true}:fetcherProps) {
    const token =  getToken()
    const result = await axios({
        method: method,
        url: url,
        data : data,
        headers: {
            Authorization: token,
            ...headers
        }
    }); 
    if (result.data.error === true) {
        if (alert) {
            SuccessMessage('custom', result.data.message)    
        }
    }else{
        return result.data.data
    }
}

export default httpFunction

