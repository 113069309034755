import Swal from 'sweetalert2';

interface MessageType {
  message: string;
  icon: "error" | "success" | "warning";

}

function SuccessMessage(type = 'success', message = '', title = '') {
  const messageType: Record<string, MessageType> = {
    success: {
      message: message || "Your file saved successfully.",
      icon: "success",
    },
    delete: {
      message: message || "Your file deleted successfully.",
      icon: "success",
    },
    error: {
      message: message || "Server Error please try again.",
      icon: "warning",
    },
    custom: {
      message: message,
      icon: "warning",
    },
  };

  return Swal.fire({
    icon: messageType[type].icon,
    title: title,
    text: messageType[type].message
  });
}

export default SuccessMessage;
