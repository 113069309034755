import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import SettingPage from 'src/views/admin/settings';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const UserLayout = Loadable(lazy(() => import('../layouts/user/UserLAyout')));


// Admin pages
const ModernDash = Loadable(lazy(() => import('../views/dashboard/Modern')));
const HotelList = Loadable(lazy(() => import('../views/admin/hotel')));
const AddHotel = Loadable(lazy(() => import('../views/admin/hotel/AddHotel')));
const EditHotel = Loadable(lazy(() => import('../views/admin/hotel/EditHotel')));
const RegistrationCode = Loadable(lazy(() => import('../views/admin/master/RegistrationCode')));
const Country = Loadable(lazy(() => import('../views/admin/master/Country')));
const State = Loadable(lazy(() => import('../views/admin/master/State')));
const City = Loadable(lazy(() => import('../views/admin/master/City')));
const ViewOrders = Loadable(lazy(() => import('../views/admin/order/ViewOrders')));
const UserList = Loadable(lazy(() => import('../views/admin/user/UserList')));

/* ****user Home ***** */
const UserHome = Loadable(lazy(() => import('../views/user')));
const LocalActivities = Loadable(lazy(() => import('../views/user/LocalActivities')));
const HotelDetail = Loadable(lazy(() => import('../views/user/HotelDetail')));
const Checkout = Loadable(lazy(() => import('../views/user/Checkout')));
const Invoice = Loadable(lazy(() => import('../views/user/Invoice')));
const OrderList = Loadable(lazy(() => import('../views/user/OrderList')));



// authentication
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/auth1/Register')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/auth1/ForgotPassword')));
const ForgotPassword2 = Loadable(
  lazy(() => import('../views/authentication/auth2/ForgotPassword2')),
);
const TwoSteps = Loadable(lazy(() => import('../views/authentication/auth1/TwoSteps')));
const TwoSteps2 = Loadable(lazy(() => import('../views/authentication/auth2/TwoSteps2')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

// landingpage
const Landingpage = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage')));

const Router = [
  {
    path: '/',
    element: <UserLayout />,
    children: [
      { path: '/', element: <UserHome /> },
      { path: '/local-activities', element: <LocalActivities /> },
      { path: '/hotel/:id', element: <HotelDetail /> },
      { path: '/checkout', element: <Checkout /> },
      { path: '/invoice/:id', element: <Invoice /> },
      { path: '/orders', element: <OrderList /> },
    ],
  },
  {
    path: '/',
    element: <FullLayout />,
    children: [
      
      { path: '/admin/dashboard', exact: true, element: <ModernDash /> },
      { path: '/admin/hotel', exact: true, element: <HotelList /> },
      { path: '/admin/hotel/add', exact: true, element: <AddHotel /> },
      { path: '/admin/hotel/:id', exact: true, element: <EditHotel /> },
      { path: '/admin/master/country', exact: true, element: <Country /> },
      { path: '/admin/master/registration-code', exact: true, element: <RegistrationCode /> },
      { path: '/admin/master/state', exact: true, element: <State /> },
      { path: '/admin/master/city', exact: true, element: <City /> },
      { path: '/admin/orders/view', exact: true, element: <ViewOrders /> },
      { path: '/admin/user/view', exact: true, element: <UserList /> },
      { path: '/admin/settings', exact: true, element: <SettingPage /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/forgot-password2', element: <ForgotPassword2 /> },
      { path: '/auth/two-steps', element: <TwoSteps /> },
      { path: '/auth/two-steps2', element: <TwoSteps2 /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '/landingpage', element: <Landingpage /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
