import { useState, useEffect } from "react";
import axios from "../utils/axios";
import SuccessMessage from "../utils/successMessage";
import { getToken } from "src/utils/localStorageHelper";

const useHttp = () => {
    const jwt = getToken()
    const [response, setResponse] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); //different!
    
    

    const httpPost = async ({url, data, headers, noAlert = false,returnRes}:{url:string, data?:object|string, headers?:object, noAlert ?: boolean, returnRes?:boolean}) => {
        try {
            setLoading(true);            
            const res = await axios.post(url, data, {
                headers : {
                    Authorization:  jwt,
                    ...headers
                }
                },
            );
            if (res.data?.error !== false || res.status !== 200) {
                if (!noAlert) {
                    SuccessMessage('custom', res.data?.message)
                }
                if (returnRes) {
                    return res
                }
            } else {
                return res
            }
        } catch (err) {
            SuccessMessage('error')
        } finally {
            setLoading(false);
        }
    }

    const httpGet = async ({url, returnRes = true,data ={}, noAlert = false}:{url:string, returnRes?:boolean, data?:object|string, noAlert?:boolean}) => {
        try {
            setLoading(true);
            const res = await axios.get(url,{
                data : data,
                headers : {
                    Authorization :  jwt,
                }
            }
            );
            if (res.data?.error !== false || res.status !== 200) {
                if (!noAlert) {
                    SuccessMessage('custom', res.data?.message)    
                }                
                if (returnRes) {
                    return res.data
                }
            } else {
                if (returnRes) {
                    return res.data
                }else{
                    setResponse(res.data?.data)                    
                }
            }
            
        } catch (err) {      
            // console.log(err);      
            SuccessMessage('error')
        } finally {
            setLoading(false);
        }
    }

    const httpUpdate = async ({url, returnRes = true, data, headers}:{url:string, returnRes?:boolean, data?:object|string, headers?: object}) => {
        try {
            setLoading(true);
            const res:any = await axios.put(url, data, {
                headers : {Authorization:  jwt,...headers},
                
                },
            );
            if (res.data?.error !== false || res.status !== 200) {
                SuccessMessage('custom', res.data?.message)
                if (returnRes) {
                    return res
                    
                }
            } else {
                if (returnRes) {
                    return res
                    
                }else{
                    setResponse(res)
                }
            }
            
        } catch (err) {
            // console.log(err);
            SuccessMessage('error')
        } finally {
            setLoading(false);
        }
    }

    const httpDelete = async ({url}:{url:string}) => {

        try {
            setLoading(true);
            
            
            const res = await axios.delete(url, {
                headers : {Authorization:  jwt},
                
                },
            );
            if (res.data?.error !== false || res.status !== 200) {
                // console.log('res.data?.error: ', res);
                SuccessMessage('custom', res.data?.message)
            } else {
                return res
            }
            
        } catch (err) {
            // console.log(err);
            SuccessMessage('error')
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        // useEffect cleanup function
        return () => {}

    }, []);

    return {response, error, loading, httpPost, httpGet, httpUpdate, httpDelete};
}

export default useHttp