import { TextField, Typography } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import CustomButton from 'src/components/custom/CustomButton'
import ErrorDisplay from 'src/components/custom/ErrorDisplay'
import { ALL_API } from 'src/configs/apiUrls'
import useHttp from 'src/hooks/useHttp'
import useSettingsData from 'src/hooks/useSettingsData'
import { HttpResponseProps, SettingsDataProps } from 'src/types/MyTypes'
import Confirmation from 'src/utils/confirmation'

const SettingPage = () => {

    const {data, isLoading, refetch} = useSettingsData()
    const {httpPost, loading} = useHttp()


    const {
        register,
        handleSubmit, 
        watch,
        setError,
        control,
        setValue,
        formState: { errors },
      } = useForm<SettingsDataProps>({defaultValues: data})

      const onSubmitHandler = async(data: SettingsDataProps) => {
        const confirm = await Confirmation({})
        if (confirm) {
            const result = await httpPost({url: ALL_API.SETTINGS.update_days_before_order, data: data, returnRes: true})
            if (result) {
                const returnData:HttpResponseProps = result.data
                if (returnData.error) {
                    type FieldNames = keyof SettingsDataProps;
                    for (const fieldName in returnData.data) {
                        if (returnData.data.hasOwnProperty(fieldName)) {
                        setError(fieldName as FieldNames, { message: returnData.data[fieldName][0] });
                        }
                    }
                }
            }
        }
      }

      useEffect(() => {
        if (data) {
            type FieldNames = keyof SettingsDataProps;
            for (const fieldName in data) {
                if (data.hasOwnProperty(fieldName)) {
                    // @ts-ignore
                    setValue(fieldName as FieldNames, data[fieldName]);
                }
            }
        }
        return () => {}

      },[data])

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className='space-y-3'>
        <Typography variant='h4'>Settings Page</Typography>
        <div className='flex space-x-2'>
            <div className='w-1/5'>
                <TextField {...register("days_before_order", {required: "This field is required."})} label='Set total days after booking' fullWidth/>
                {errors.days_before_order?.message && <ErrorDisplay err={[errors.days_before_order?.message]}/>}
            </div>
            
            <div className='w-1/5'>
                <TextField {...register("days_before_order_message", {required: "This field is required."})} label='Set total days after booking alert message' fullWidth/>
                {errors.days_before_order_message?.message && <ErrorDisplay err={[errors.days_before_order_message?.message]}/>}
            </div>
        </div>

        <CustomButton type='submit' loading={loading} fullWidth>Save</CustomButton>

    </form>
  )
}

export default SettingPage
