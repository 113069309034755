import React from 'react'
import { useQuery } from 'react-query';
import httpFunction from 'src/utils/httpFunction';
import { SettingsDataProps } from 'src/types/MyTypes';
import { ALL_API } from 'src/configs/apiUrls';

const useSettingsData = () => {
    const {isLoading,data, refetch} = useQuery("settings", () => httpFunction({url:ALL_API.SETTINGS.MAIN, method:"GET"})) as { isLoading: boolean; data: SettingsDataProps; refetch: () => void };
    return {isLoading, data, refetch}
}

export default useSettingsData
