import Swal from 'sweetalert2';

const Confirmation = ({title,message,buttonText}:{title?:string, message?:string, buttonText?: string}) => {
  return new Promise((resolve) => {
    Swal.fire({
      
      title: title ? title : 'Are you sure?',
      text:  message ? message : "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: buttonText ? buttonText : 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

export default Confirmation;
