// Set an item in localStorage
export const setLocalStorageItem = (key:string, value:object) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  };
  
  // Retrieve an item from localStorage
  export const getLocalStorageItem = (key:string) => {
    if (typeof window !== 'undefined') {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    }
    return null;
  };

  export function deleteLocalStorageItem(key:string) {
    if (typeof window !== 'undefined') {
       window.localStorage.removeItem(key);
    }
  }


export const getToken = () => {
  const token = getLocalStorageItem("token")
  if (token) {
    return "token " + token
  }else{
    return ""
  }
    
}