import React, { MouseEventHandler } from 'react';
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Fab, Tooltip, FabProps } from '@mui/material';
import Confirmation from 'src/utils/confirmation';

interface Props extends LoadingButtonProps {
  title ?: string
  fab?: boolean;
  icon?: JSX.Element;
  fabProps ?: FabProps
  confirmation ?: boolean
}

const CustomButton: React.FC<Props> = (props) => {
  const { fab, icon, title, fabProps, confirmation, onClick , ...otherProps } = props; // Destructure props

  const confirmationHandler = async () => {
    const confirm = await Confirmation({}); // Assuming Confirmation returns a boolean
    if (confirm) {
      if (onClick) {
        // @ts-ignore
        onClick(); // Call the original onClick function if confirmed  
      }
    }
  };

  if (fab) {
    return (
      <Tooltip title={title}>
        <Fab color="primary" aria-label={title} onClick={confirmation ? confirmationHandler : onClick} {...fabProps}>
          {icon}
        </Fab>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={title}>
        <LoadingButton {...otherProps} onClick={confirmation ? confirmationHandler : onClick}/>
      </Tooltip>
    );
  }
};

export default CustomButton;
