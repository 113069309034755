// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'

// ** Next Import


// ** Axios
import axios from 'src/utils/axios'

// ** Config

// ** Types
import { AuthValuesType, LoginParams, ErrCallbackType, UserDataType, RegistrationParam } from './types'
import { ALL_API } from 'src/configs/apiUrls'
import { deleteLocalStorageItem, getToken, setLocalStorageItem } from 'src/utils/localStorageHelper'
import { useNavigate } from 'react-router'
import { USER_ROLES } from 'src/configs/auth'
import SuccessMessage from 'src/utils/successMessage'

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  registration: () => Promise.resolve(),
  logout: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)

  // ** Hooks
  const router = useNavigate()

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      try {
        const currentURL = window.location.pathname;;

        const storedToken = getToken()
        if (storedToken) {
          setLoading(true)
          await axios.get(ALL_API.AUTH.me, {
              headers: {
                Authorization: storedToken
              }
            })
            .then(async response => {
              setLoading(false)
              if (response.data.error === false) {
                setUser({ ...response.data.data })
                if (response.data.data.role === USER_ROLES.ADMIN) {
                  router(currentURL, {replace: true})
                }else{
                  router(currentURL, {replace: true})
                }
              }else{
                deleteLocalStorageItem("token")
                setUser(null)
                setLoading(false)
                router('/auth/login', {replace: true})
              }
              
            })
            .catch(() => {
              deleteLocalStorageItem("token")
              setUser(null)
              setLoading(false)
              router('/auth/login', {replace: true})
            })
        } else {
          router('/auth/login', {replace: true})
          setLoading(false)
        }
    } catch (error) {
    
      deleteLocalStorageItem("token")
      setUser(null)
      setLoading(false)
      router('/auth/login', {replace: true})
    }
  }

    initAuth()
    
  }, [])

  const handleLogin = async(params: LoginParams, errorCallback?: ErrCallbackType) => {
    try {
      const login = await axios.post(ALL_API.AUTH.Login, params)
      if (login.status !== 200) {
        errorCallback && errorCallback({error: true, data :login.data.data, message: ""})
      }else{
          setLocalStorageItem("token", login.data.data.accessToken)

          setUser({ ...login.data.data })

          if (login.data.data.role === USER_ROLES.ADMIN) {
            router("/admin/dashboard", {replace: true})
          }else{
            router("/", {replace: true})
          }
          // const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'

      }
    } catch (error) {
      // errorCallback && errorCallback("server error")
    }
  }

  const handleRegistration = async(params: RegistrationParam, errorCallback?: ErrCallbackType) => {
    try {
      const login = await axios.post(ALL_API.AUTH.REGISTER, params)
      if (login.data.error) {
        SuccessMessage("custom", login.data.message)
      }
      errorCallback && errorCallback({error : login.data.error, message : login.data.message, data: login.data.data})
    } catch (error) {
      // errorCallback && errorCallback("server error")
    }
  }


  const handleLogout = () => {
    setUser(null)
    deleteLocalStorageItem("token")
    router('/auth/login', {replace: true})
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    registration: handleRegistration
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
